new WOW().init();
const now = new Date();

const external_ref = Math.round(now.getTime() / 1000);
var sdx_url = "https://us2.surveydynamix.com/web_survey/f84e51b0-bfae-11ea-bf42-a7dd097972e1?external_ref="+external_ref+"&responden_language=en-US";
window._genesys = {
  "widgets": {
    "webchat": {
      "transport": {
        "type": "purecloud-v2-sockets",
        "dataURL": "https://api.mypurecloud.com",
        "deploymentKey": "e7e735fb-97b2-4963-89dc-ff434b9f0da3",
        "orgGuid": "7f20556b-100a-409e-996d-7d6e740ae2cb",
        "interactionData": {
          "routing": {
            "targetType": "QUEUE",
            "targetAddress": "VT_EN_CHAT",
            "priority": 2
          }
        }
      },
      "userData": {
        "SDX_external_ref": external_ref,
        "addressStreet": "",
        "addressCity": "",
        "addressPostalCode": "",
        "addressState": "",
        "phoneNumber": "",
        "customField1Label": "",
        "customField1": "",
        "customField2Label": "",
        "customField2": "",
        "customField3Label": "",
        "customField3": ""
      }
    }
  }
};
function getCountry() {
  var req = new XMLHttpRequest();
  req.open("GET", 'https://api.ipgeolocation.io/ipgeo?apiKey=3656fa6591f248ff8840398b6d1e4e2f', true);
  req.send();
  req.onload = function () {
    var data = JSON.parse(this.response);
    var country = data.country_code2;

    if (country != null && (country == 'US' || country == 'CA'))
      customPlugin.command('WebChat.open', getAdvancedConfig());
    else
      alert('chat is available only in US or Canada');
  }

}

function showchat() {
  var country = getCountry();
}
function getAdvancedConfig() {
  return {
    "form": {
      "autoSubmit": false,
      "firstname": "",
      "lastname": "",
      "email": "",
      "subject": ""
    },
    "formJSON": {
      "wrapper": "<table></table>",
      "inputs": [
        {
          "id": "cx_webchat_form_firstname",
          "name": "firstname",
          "maxlength": "100",
          "placeholder": "Optional",
          "label": "First Name"
        },
        {
          "id": "cx_webchat_form_lastname",
          "name": "lastname",
          "maxlength": "100",
          "placeholder": "Optional",
          "label": "Last Name"
        },
        {
          "id": "cx_webchat_form_email",
          "name": "email",
          "maxlength": "100",
          "placeholder": "Optional",
          "label": "Email"
        },
        {
          "id": "cx_webchat_form_subject",
          "name": "subject",
          "maxlength": "100",
          "placeholder": "Optional",
          "label": "Subject"
        }
      ]
    }
  };
}

const customPlugin = CXBus.registerPlugin('Custom');
customPlugin.subscribe('WebChatService.started', function (e) {
});
customPlugin.subscribe('WebChatService.agentDisconnected', function (e) {
  $('#surveyWindow').removeClass('hide');
  $('#surveyWindow').html('<div class="close-bar" >Survey form<span class="close-survey-window close"></span></div>');
  var iframe = document.createElement('iframe');
  iframe.setAttribute('id', 'sdx-survey-iframe');
  document.getElementById('surveyWindow').appendChild(iframe);
  iframe.setAttribute('src', sdx_url);
  iframe.setAttribute('class', 'survey-iframe');
});





$(document).on('click', '.close-survey-window', function () {
    $('#surveyWindow').addClass("hide");
  });

  $(document).on('click', '.cx-close-confirm, .cx-end-confirm ', function () {
    setTimeout(function () {
      $('.cx-disabled .cx-button-close').trigger('click');
    }, 500)

    $('.chat-box').removeClass('active');
  });

  $(document).on('click', '.chat-box, .btn-connect', function () {
    $('.chat-box').addClass('active'), 500;
  });
  $(document).on('click', '.cx-buttons-window-control .cx-button-close', function () {
    $('.chat-box').removeClass('active');
  });


  jQuery('.hria-logo, .idhs-logo').mouseenter(function () {
    var src = jQuery(this).attr('src');
    jQuery(this).attr('src', src.replace("REST", "HOVER"));
  }).mouseleave(function () {
    var src = $(this).attr('src');
    jQuery(this).attr('src', src.replace("HOVER", "REST"));
  });