$(document).on('click', "#btn_content.read-m", function () {
    $("#post_excerpt").addClass("d-none");
    $("#post_content").removeClass("d-none");
    $("#btn_learn_content").removeClass("d-none");
    $("#btn_content").addClass("d-none");
});

$(document).on('click', "#btn_learn_content.learn-m", function () {
    $("#post_content").addClass("d-none");
    $("#post_excerpt").removeClass("d-none");
    $("#btn_content").removeClass("d-none");
    $("#btn_learn_content").addClass("d-none");
});

$(document).on('click', "#btn_content1.read-m", function () {
    $("#post_excerpt1").addClass("d-none");
    $("#post_content1").removeClass("d-none");
    $("#btn_learn_content1").removeClass("d-none");
    $("#btn_content1").addClass("d-none");
});

$(document).on('click', "#btn_learn_content1.learn-m", function () {
    $("#post_content1").addClass("d-none");
    $("#post_excerpt1").removeClass("d-none");
    $("#btn_content1").removeClass("d-none");
    $("#btn_learn_content1").addClass("d-none");
});